import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TypeProperty } from '@contrail/types';
@Component({
  selector: 'app-object-reference-chip',
  templateUrl: './object-reference-chip.component.html',
  styleUrls: ['./object-reference-chip.component.scss'],
})
export class ObjectReferenceChipComponent implements OnChanges {
  @Input() object: any;
  @Input() typeProperty: TypeProperty;
  @Input() edit = false;
  @Input() maxDisplayLength = 16;
  @Input() allowDisplayTextShortening = true;
  @Input() clickable = true;
  @Output() clearValue = new EventEmitter();
  @Output() clicked = new EventEmitter();

  public objectName: string;
  public objectNumber: string;
  public hasStatus: boolean;
  public colorStage: string;
  constructor() {}

  async ngOnChanges() {
    // console.log('CHIP: ', this.object );
    this.objectName = this.object.name;
    this.colorStage = null;
    if (this.object?.typeId) {
      if (this.object.itemStatus === 'released') {
        this.colorStage = 'released';
        this.hasStatus = true;
      } else if (this.object.itemStatus === 'development') {
        this.colorStage = 'development';
        this.hasStatus = true;
      } else if (this.object.itemStatus) {
        this.hasStatus = true;
      }
    }

    const isItemObject = this.typeProperty?.referencedTypeRootSlug === 'item';
    if (isItemObject) {
      this.setObjectNameForItem();
    }
  }

  private setObjectNameForItem() {
    const isItemOptionProperty = this.typeProperty.slug === 'itemOption';
    const isOptionReference = this.typeProperty.referencedTypeRole === 'option';

    if (isItemOptionProperty) {
      this.objectName = this.object.optionName;
      return;
    }

    if (isOptionReference) {
      this.objectName = `${this.object.name} - ${this.object.optionName}`;
      return;
    }

    this.objectName = this.object.name;
  }

  remove() {
    this.clearValue.emit();
  }

  viewDetails() {
    if (this.clickable) {
      this.clicked.emit();
    }
  }
}
